import axios from "axios";
import { ActionRow } from "components/parts/ActionRow";
import DeleteModal from "components/parts/DeleteModal";
import { formatDate, formatDateAndTime } from "helpers/date";
import { A_ASSIGN_WORKER } from "models/authorities";
import { DISTRICTS } from "models/districtModels";
import { FetchAssignedWorkers, FetchStaffHistory } from "models/responseModels";
import { AreaType, DIVISIONS, DivisionType, SERVICE_TYPES } from "models/variables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Nav, Row, Spinner, TabContent } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import { assignWorkerToList, resetWorkerList } from "redux/Order/action";
import { setViewWorkerHistory, setViewWorkerModalData, setViewWorkerModalOpen } from "redux/ViewWorkerModal/action";
import { resetWorkerPagination, setWorkerChangesMade, setWorkerCurrentPage } from "redux/Worker/action";
import { IRootState } from "store";
import { CloseIcon, DeleteIcon, SearchIcon, WhatsappIcon } from "./IconsOnly";
import FormHeader from "./parts/FormHeader";
import { AssignList } from "./parts/NewOrder/NewAdminDetails";
import ReactstrapTabNavLink from "./parts/ReactstrapTabNavLink";
import SortableTableHeaders from "./parts/SortableTableHeaders";
import ViewWorkerModal from "./parts/ViewWorkerModal";

interface IWorkerInformation {
  header: string;
  isNewOrderMode?: boolean;
}

export interface AllWorkersType {
  id: number;
  chiName: string;
  mobile: string;
  staffNumber: string;
  role: string;
  division: DivisionType;
  residenceDistrict: string;
  area: AreaType;
}

export default function AssignWorker({ header, isNewOrderMode = false }: IWorkerInformation) {
  const [modal, setModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [assignUserOpen, setAssignUserOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("WORKER");
  const [allUsers, setAllUsers] = useState<AllWorkersType[]>([]);
  const [workerLists, setWorkerLists] = useState<{
    supervisorList: AllWorkersType[];
    foremenList: AllWorkersType[];
    workerList: AllWorkersType[];
  }>({ supervisorList: [], foremenList: [], workerList: [] });
  const [assignedWorkers, setAssignedWorkers] = useState<FetchAssignedWorkers[]>([]);
  const [originalAssignedWorkers, setOriginalAssignedWorkers] = useState<number[]>([]);
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [lastPage, setLastPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [modalSearchText, setModalSearchText] = useState("");
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "role",
    isDesc: true,
  });
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const sortableHeaders = [
    { headerText: "動作", headerKey: "" },
    { headerText: "員工編號", headerKey: "staffNumber" },
    { headerText: "中文姓名", headerKey: "chiName" },
    { headerText: "英文姓名", headerKey: "engName" },
    { headerText: "聯絡電話", headerKey: "mobile" },
    { headerText: "居住區域", headerKey: "residenceDistrict" },
    { headerText: "所屬公司", headerKey: "company" },
    { headerText: "所屬部門", headerKey: "division" },
    { headerText: "員工職位", headerKey: "role" },
    { headerText: "加入日期", headerKey: "createdAt" },
  ];

  const tabs = ["WORKER", "FOREMEN", "SUPERVISOR"];

  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const pagination = useSelector((state: IRootState) => state.worker.pagination);
  const changesMade = useSelector((state: IRootState) => state.worker.changesMade);
  const orderById = useSelector((state: IRootState) => state.orderById);
  const assignWorkers = orderById.assignWorkers;
  const orderNumber = orderById.orderById.orderNumber;
  const pathname = window.location.pathname.split("/");
  const orderId = pathname[pathname.length - 1];

  function toggle(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const fetchAllUsers = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
      url.searchParams.set("type", "form");
      url.searchParams.set("orderId", `${orderId}`);
      if (!!modalSearchText) url.searchParams.set("search", modalSearchText);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAllUsers(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setIsLoading(false));
  };

  const fetchAssignedWorkers = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/order-staffs/${orderId}`);
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAssignedWorkers(result.data);
      for (const i of result.data) {
        dispatch(assignWorkerToList(i.id));
      }
      setOriginalAssignedWorkers(result.data.map((i: any) => i.id));
      setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetchAssignedWorkers(1);
    fetchGuestSatisfactory();
    fetchAllUsers();
    if (changesMade) {
      fetchAssignedWorkers(pagination.currentPage);
      fetchAllUsers();
      dispatch(setWorkerCurrentPage(1));
      dispatch(setWorkerChangesMade(false));
    } else {
      fetchAssignedWorkers(1);
      fetchAllUsers();
    }
    dispatch(setIsLoading(false));
    return () => {
      dispatch(resetWorkerPagination());
      dispatch(resetWorkerList());
      dispatch(setWorkerCurrentPage(1));
    };
  }, []);

  useEffect(() => {
    setWorkerLists({
      supervisorList: allUsers.filter((i) => i.role === "SUPERVISOR"),
      foremenList: allUsers.filter((i) => i.role === "FOREMEN"),
      workerList: allUsers.filter((i) => i.role === "WORKER"),
    });
  }, [allUsers, assignedWorkers, assignWorkers, assignUserOpen, changesMade]);

  useEffect(() => {
    dispatch(resetWorkerList());
    fetchAllUsers();
    fetchAssignedWorkers(pagination.currentPage);
  }, [dispatch, pagination.currentPage, modal, assignUserOpen, changesMade]);

  const postAssignWorkersToOrder = async () => {
    const newArr = assignWorkers.filter((i) => !originalAssignedWorkers.includes(i));
    try {
      await axios.post(`/order-staffs/${orderId}`, {
        staffIds: newArr,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      setAssignUserOpen(false);
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleSelect = async (item: FetchAssignedWorkers) => {
    dispatch(setIsLoading(true));
    dispatch(setViewWorkerModalData(item));
    try {
      const res = await axios.get<{ data: FetchStaffHistory[] }>(`/users/${item.id}`);
      const result = await res.data;
      dispatch(setViewWorkerHistory(result.data));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setViewWorkerModalOpen(true));
    dispatch(setIsLoading(false));
  };

  const fetchGuestSatisfactory = async () => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result = await res.data;
      if (!result.data) {
        setIsNew(true);
      } else {
        setIsNew(false);
      }
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const windowSize = "height=600,width=400,left=100,top=100";

  const loadMessage = (phoneNumber: string) => {
    const order = orderById.orderById;
    const message = encodeURIComponent(
      `*新工作單安排${orderNumber ?? ""}*
      \n客戶：${order.clientName}\n地址：${order.clientAddress ?? "-"}\n預計開始日期：${
        order.expectedStartDate ? formatDate(order.expectedStartDate) : "-"
      }\n預計完工日期：${order.expectedCompleteDate ? formatDate(order.expectedCompleteDate) : "-"}\n服務性質：${
        order.serviceType ? SERVICE_TYPES[order.serviceType] : "-"
      }\n服務事項：\n${
        order.serviceSubject ?? "-"
      }\n\n請按以下連結檢視工作單：\nhttps://mobile.wingold.hk/order/${order.id}\n\n如有其他查詢請與公司聯絡。`
    );
    window.open(`https://wa.me/852${phoneNumber}?text=${message}`, "ViewCoMessage", windowSize);
  };

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
    fetchAssignedWorkers(1);
  };
  const handleSearch = () => {
    fetchAssignedWorkers(1);
  };

  return (
    <div className="p-3 relative" style={{ overflowX: "hidden" }}>
      <FormHeader offset={false} header={header} />
      {!isNewOrderMode && (
        <ActionRow
          lastPage={lastPage}
          currentPage={pagination.currentPage}
          addItem={"工程單員工"}
          setCurrentPageFunction={setWorkerCurrentPage}
          isNew={false}
          modalIsOpen={assignUserOpen}
          setModalIsOpen={setAssignUserOpen}
          handleSearch={handleSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          placeholderText={"員工編號 / 姓名 / 電話 / 公司"}
        />
      )}
      {!assignWorkers.length ? (
        <Row className="my-4 d-flex justify-content-center" style={{ minHeight: "64px" }}>
          <Col className="d-flex justify-content-center">
            <h5 className="disableText">尚未加入員工</h5>
          </Col>
        </Row>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                assignedWorkers.map((item, idx) => {
                  return (
                    <div key={idx} className={`flex-center tableRow`}>
                      <div className="flex-row-around tdActionContainer pointer">
                        <div
                          className="flex-center tdIconContainer full-height"
                          onClick={() => loadMessage(item.mobile)}
                        >
                          <WhatsappIcon />
                        </div>
                        {((A_ASSIGN_WORKER.includes(userRole) && isNew) || userRole === "SUPER_ADMIN") && (
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              setModal(!modal);
                              setEditTarget({ name: item.chiName, id: item.id });
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.staffNumber || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.chiName || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.engName || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.mobile || "-"}
                      </div>
                      <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
                        {DISTRICTS[item.residenceDistrict]}
                      </div>
                      <div className="tdMainItem" onClick={() => handleSelect(item)}>
                        {item.company}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.division ? DIVISIONS[item.division] : "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.role}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
                        {formatDateAndTime(item.createdAt)}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
      <DeleteModal isOpen={modal} deleteTarget={editTarget} setModal={setModal} addItemString={"工程單員工"} />
      <Modal
        isOpen={assignUserOpen}
        className="d-flex justify-content-center"
        style={{ minWidth: "70vw", maxHeight: "90vh" }}
      >
        <Container className="p-3 relative" style={{ overflowY: "scroll" }}>
          <div className="flex-row-start">
            <div className="flex1" />
            <h2 className="fit-content">{`${orderNumber} - 加入員工`}</h2>
            <div
              className="flex1 flex-row-end"
              onClick={() => {
                setAssignUserOpen(false);
              }}
            >
              <div
                className={`closeButtonContainer ${hovered && "buttonHovered"}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <CloseIcon color="#EEE" />
              </div>
            </div>
          </div>
          <Row className="px-2 flex-row-between m-4">
            <Col md={6} className="d-flex">
              <input
                className="searchInput"
                style={{ width: "100%" }}
                value={modalSearchText}
                placeholder={"員工編號 / 中文姓名 / 手機號碼"}
                onChange={(e) => setModalSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") fetchAllUsers();
                }}
              ></input>
              <div className="mx-3" onClick={fetchAllUsers}>
                <SearchIcon />
              </div>
            </Col>
            <Col className="flex-row-end">
              <Button color="primary" onClick={postAssignWorkersToOrder}>
                確認加入員工
              </Button>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Nav tabs style={{ height: "40px" }}>
              {tabs.map((element) => {
                const props = { tabContent: element, activeTab, toggle };
                return <ReactstrapTabNavLink key={element} {...props} />;
              })}
            </Nav>

            <TabContent activeTab={activeTab} className="flex-center" style={{ width: "100%" }}>
              <form className="full-width">
                {
                  {
                    SUPERVISOR: (
                      <AssignList
                        assignList={workerLists.supervisorList}
                        closeAction={setAssignUserOpen}
                        isAssignMode={true}
                      />
                    ),
                    FOREMEN: (
                      <AssignList
                        assignList={workerLists.foremenList}
                        closeAction={setAssignUserOpen}
                        isAssignMode={true}
                      />
                    ),
                    WORKER: (
                      <AssignList
                        assignList={workerLists.workerList}
                        closeAction={setAssignUserOpen}
                        isAssignMode={true}
                      />
                    ),
                  }[activeTab]
                }
              </form>
            </TabContent>
          </div>
        </Container>
      </Modal>

      <ViewWorkerModal />
    </div>
  );
}
