import { push } from "connected-react-router";
import "css/Document.css";
import "css/Form.css";
import "css/GuestSatisfactory.css";
import { pageStyle } from "css/printCss";
import { formatDate, formatDateAndTime } from "helpers/date";
import { highLevel } from "models/authorities";
import { DISTRICTS } from "models/districtModels";
import { videoFormats } from "models/mediaFormats";
import { DIVISIONS, SERVICE_TYPES } from "models/variables";
import React, { useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Col, Row } from "reactstrap";
import { setImageModalDescription } from "redux/Order/action";
import { IRootState } from "store";
import { DynamicsLogo, LargeEditIcon, PrintIcon, StarIcon } from "./IconsOnly";
import { ImageModalType } from "./ImagesRecord";
import DynamicsDetails from "./parts/DynamicsDetails";
import FormHeader from "./parts/FormHeader";
import ViewCoHeader, { ViewCoFooter } from "./parts/ViewcoHeader";
import ViewImageModal from "./parts/ViewImageModal";

// const MAX_CANVAS_WIDTH = `${window.innerWidth / 1.9}px`;
const PRINT_WIDTH = "90%";

export default function ViewOrder() {
  const printRef = useRef<HTMLTableElement>(null);
  const [isSignatureNeeded, setIsSignatureNeeded] = useState(false);
  const [showDynamicsDetail, setShowDynamicsDetail] = useState(false);
  const [imageModal, setImageModal] = useState<{
    type: ImageModalType;
    fileName?: string;
    mediaId?: number;
  }>({
    type: "",
    fileName: "",
    mediaId: 0,
  });
  // const [files, setFiles] = useState<FileList | null>();
  const dispatch = useDispatch();
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const orderById = useSelector((state: IRootState) => state.orderById);
  const fetchedImages = orderById.fetchedImage;
  const data = orderById.orderById;
  const orderSatisfactory = orderById.orderSatisfactory;
  const signatureData = orderById.signatureData;
  const assignedWorkers = orderById.assignedWorkers;
  // const headers = ["員工編號", "中文姓名", "英文姓名", "手機號碼", "居住區域", "所屬公司", "所屬部門", "員工職位"];

  const goToEdit = () => {
    if (data.division === "PROJECT") {
      dispatch(push(`/service/work/${data.id}`));
    }
    if (data.division === "MAINTENANCE") {
      dispatch(push(`/maintenance/work/${data.id}`));
    }
  };

  const handleExit = () => {
    setImageModal({ type: "" });
    dispatch(setImageModalDescription(""));
    // setFiles(null);
  };
  return (
    <div className="p-3 relative" style={{ overflowY: "scroll" }}>
      <FormHeader
        offset={false}
        header={`工作單 - ${data.orderNumber}`}
        includeExtraButton
        children={
          <div className="px-4 flex-column-start absolute red" style={{ right: 0, top: 0, background: "white" }}>
            <div className={`flex-column-center menuItemsContainer`}>
              <div className="flex-column-center pointer my-3" onClick={goToEdit}>
                <div className="flex-center printIconContainer" style={{ boxShadow: "inset 0 2px 4px #fff6" }}>
                  <LargeEditIcon />
                </div>
                <div className="toggleButtonText">修改</div>
              </div>
              <ReactToPrint
                bodyClass={"contentContainer"}
                pageStyle={pageStyle}
                content={() => printRef.current}
                trigger={() => (
                  <div className="printButtonContainer my-3">
                    <div className="flex-center printIconContainer">
                      <PrintIcon />
                    </div>
                    <div className="toggleButtonText">列印文件</div>
                  </div>
                )}
              />
              <div className="signatureButtonContainer my-3">
                <div className="toggleButtonContainer" onClick={() => setIsSignatureNeeded(!isSignatureNeeded)}>
                  <div
                    className="fullToggleButton"
                    style={{
                      left: isSignatureNeeded ? "20px" : "-26px",
                    }}
                  >
                    <div style={{ height: "100%", marginRight: "8px" }}>ON</div>
                    <div className="toggleButton" style={{ background: isSignatureNeeded ? "#306ef4" : "#888" }} />
                    <div style={{ height: "100%", marginLeft: "8px" }}>OFF</div>
                  </div>
                </div>
                <div className="toggleButtonText">完工紙</div>
              </div>
              {highLevel.includes(userRole) && (
                <div
                  className="flex-column-center pointer my-3"
                  onClick={() => setShowDynamicsDetail(!showDynamicsDetail)}
                >
                  <div className="flex-center" style={{ boxShadow: "inset 0 2px 4px #fff6" }}>
                    <DynamicsLogo />
                  </div>
                  <div className="toggleButtonText">Dynamics</div>
                </div>
              )}
            </div>
          </div>
        }
      />
      <div
        className="full-width flex-column-center"
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <div className="A4paper">
          <table ref={printRef} id="viewcoTable" style={{ width: "100%" }}>
            <thead>
              <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
                <div style={{ height: "120px" }} />
                <ViewCoHeader />
              </div>
            </thead>
            <tbody className="flex-column-center" id="viewcoContent" style={{ width: "100%" }}>
              <div id="viewcoContent" style={{ width: PRINT_WIDTH }}>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 my-2">
                    {isSignatureNeeded && <h5 className="my-2 flex-center">{"JOB COMPLETION SHEET 工程完工單"}</h5>}
                    <SectionHeader headerText={"客戶資料"} />
                    <Row className={`p-2 my-2 d-flex`}>
                      <Col>
                        <KeyValue keyText={"名稱:"} valueText={data.clientName || "-"} />
                        <KeyValue keyText={"地址:"} valueText={data.clientAddress || "-"} />
                        <KeyValue keyText={"地區:"} valueText={data.district ? DISTRICTS[data.district] : "-"} />
                        <KeyValue keyText={"Customer Type:"} valueText={data.clientType || "-"} />
                      </Col>
                      <Col>
                        <KeyValue keyText={"聯絡人:"} valueText={data.clientContactPerson || "-"} />
                        <KeyValue keyText={"聯絡電話:"} valueText={data.clientTel || "-"} />
                        <KeyValue keyText={"電郵地址:"} valueText={data.clientEmail || "-"} />
                      </Col>
                    </Row>
                  </section>
                </tr>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 my-2">
                    <SectionHeader headerText={"行政資料"} />
                    <Row className={`p-2 my-2 d-flex`}>
                      <Col>
                        <KeyValue keyText={"發單者:"} valueText={data.issuedByUserName} />
                        <KeyValue keyText={"消息來源:"} valueText={data.informedBy} />
                        <KeyValue keyText={"ACJ 號碼 / P.O. 號碼:"} valueText={data.acjNo ?? " - "} />
                      </Col>
                      <Col>
                        <KeyValue keyText={"工作單號:"} valueText={data.orderNumber} />
                        <KeyValue keyText={"部門:"} valueText={data.division ? DIVISIONS[data.division] : "-"} />
                        <KeyValue
                          keyText={"服務性質:"}
                          valueText={data.serviceType ? SERVICE_TYPES[data.serviceType] : "-"}
                        />
                      </Col>
                    </Row>
                  </section>
                </tr>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 my-2">
                    <div>
                      <SectionHeader headerText={"進度槪覽"} />
                      <Row className={`p-2 my-2 d-flex`}>
                        <Col>
                          <KeyValue
                            keyText={"開始日期:"}
                            valueText={data.startDate ? formatDate(data.startDate) : " - "}
                          />
                          <KeyValue
                            keyText={"完成日期:"}
                            valueText={data.completeDate ? formatDate(data.completeDate) : " - "}
                          />
                        </Col>
                        <Col>
                          <KeyValue keyText={"總收費:"} valueText={!!data.totalPrice ? `${data.totalPrice}` : "0"} />
                          <KeyValue
                            keyText={"已收取費用:"}
                            valueText={!!data.paymentMade ? `${data.paymentMade}` : "0"}
                          />
                        </Col>
                      </Row>
                    </div>
                  </section>
                </tr>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 mt-2">
                    <SectionHeader headerText={"參與員工"} />
                    <Row className={`p-2 my-2 d-flex`}>
                      {!assignedWorkers || assignedWorkers.length === 0 ? (
                        <Row className="d-flex justify-content-center">
                          <Col className="d-flex justify-content-center">
                            <h5 className="flex-center disableText">沒有參與的員工</h5>
                          </Col>
                        </Row>
                      ) : (
                        <div className="flex-row-start" style={{ height: "100%", maxWidth: "100%", overflowX: "auto" }}>
                          {assignedWorkers.map((item) => {
                            return (
                              <div key={item.id} className="mx-2">
                                {item.chiName || "-"}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Row>
                  </section>
                </tr>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4">
                    <SectionHeader headerText={"服務事項"} />
                    <Row className={`p-2 my-2 d-flex`}>
                      <Col>
                        <KeyValue keyText={""} valueText={data.serviceSubject ?? " - "} useTextareaAutosize />
                      </Col>
                    </Row>
                  </section>
                </tr>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 " style={{ pageBreakInside: "avoid" }}>
                    <SectionHeader headerText={"服務報告"} />
                    <Row className={`p-2 my-2 d-flex`}>
                      <Col>
                        <KeyValue keyText={""} valueText={data.serviceReport ?? " - "} useTextareaAutosize />
                      </Col>
                    </Row>
                  </section>
                </tr>
                <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 mt-2 mb-4">
                    <SectionHeader headerText={"圖片記錄"} />
                    {fetchedImages && !fetchedImages.length ? (
                      <Row className="d-flex justify-content-center">
                        <Col className="d-flex justify-content-center">
                          <h5 className="flex-center disableText">暫無圖片記錄</h5>
                        </Col>
                      </Row>
                    ) : (
                      <div className="full-width" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        {fetchedImages
                          ?.filter((i) => !videoFormats.includes(i.fileName.split(".")[1]))
                          .map((item: any) => {
                            return (
                              <div
                                key={item.id}
                                className="my-3 p-2 pb-0 flex-column-center imageContainer pointer"
                                style={{ pageBreakInside: "avoid" }}
                                onClick={() => {
                                  setImageModal({
                                    type: "VIEW",
                                    fileName: item.fileName,
                                    mediaId: item.id,
                                  });
                                  dispatch(setImageModalDescription(item.description));
                                }}
                              >
                                {!videoFormats.includes(item.fileName.split(".")[1]) && (
                                  <>
                                    <img
                                      className="image"
                                      src={`https://wingold-s3.s3.ap-east-1.amazonaws.com/${item.fileName}`}
                                      style={{ maxHeight: "20vh" }}
                                      alt={`${item.fileName}`}
                                    />
                                    <TextareaAutosize
                                      className="full-width pointer-events-none flex-column-start py-1"
                                      readOnly
                                      value={item.description}
                                      style={{ border: "none", paddingBottom: "40px" }}
                                    />
                                    <div className="dateContainer">{formatDateAndTime(item.createdAt)}</div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </section>
                </tr>
                <tr className="my-1 d-flex full-width" style={{ alignItems: "flex-start", pageBreakInside: "avoid" }}>
                  <section className="full-width px-4 mb-1">
                    <SectionHeader headerText={"客戶滿意度"} />
                    <div className="p-3 full-width">
                      {orderSatisfactory ? (
                        <div
                          className="flex-column-center full-width pointer-events-none"
                          style={{ position: "relative" }}
                        >
                          <StarCardForPrint title={"服務質量"} score={orderSatisfactory.serviceQuality} />
                          <StarCardForPrint title={"　　技術"} score={orderSatisfactory.technical} />
                          <StarCardForPrint title={"　　溝通"} score={orderSatisfactory.communication} />
                          <StarCardForPrint title={"　　安全"} score={orderSatisfactory.safety} />
                          {/* <div className="my-4 full-width" style={{ maxWidth: MAX_CANVAS_WIDTH }}> */}
                          <div className="my-4 full-width">
                            <h5 style={{ fontSize: "1.2em", textAlign: "center" }}>客戶簽署</h5>
                            <div
                              className="flex-center full-width signatureContainer relative"
                              style={{ height: "280px" }}
                            >
                              <div
                                className="absolute"
                                style={{ background: "white", right: 0, top: 0, padding: "0 16px" }}
                              >
                                {formatDateAndTime(orderSatisfactory.createdAt)}
                              </div>
                              {signatureData && (
                                <img
                                  src={signatureData}
                                  // style={{ maxHeight: "100%", height: "auto", maxWidth: MAX_CANVAS_WIDTH }}
                                  style={{ maxHeight: "120px", width: "auto" }}
                                  alt="Signature"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Row className="d-flex justify-content-center">
                          <Col className="d-flex justify-content-center">
                            <h5 style={{ color: "#AAA" }}>尚未評分</h5>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </section>
                </tr>
                {isSignatureNeeded && (
                  <tr className="my-1 d-flex formFields" style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}>
                    <JobCompletionSignatureFields />
                  </tr>
                )}
                <div style={{ height: "40px" }} />
              </div>
            </tbody>
            <tfoot>
              <div style={{ height: "80px" }}></div>
              <div id="viewCoFooter" className="full-width flex-row-start viewCoHeader">
                <ViewCoFooter />
              </div>
            </tfoot>
          </table>
        </div>
      </div>
      <ViewImageModal
        imageModal={imageModal}
        image={undefined}
        handleExit={handleExit}
        onSubmit={() => {}}
        submitEdit={() => {}}
        isUploadMode={false}
      />
      <DynamicsDetails
        isOpen={showDynamicsDetail}
        closeModal={() => {
          setShowDynamicsDetail(false);
        }}
      />
    </div>
  );
}

interface KeyValueProps {
  keyText?: string;
  valueText: string | undefined;
  useTextareaAutosize?: boolean;
}

export const KeyValue = (props: KeyValueProps) => {
  const { keyText, valueText, useTextareaAutosize = false } = props;
  return (
    <div className="flex-row-column-start">
      {keyText && (
        <h6 className="m-0 flex-row-column-start" style={{ minWidth: "max-content", lineHeight: "unset" }}>
          {keyText}
        </h6>
      )}
      {useTextareaAutosize ? (
        <TextareaAutosize value={valueText} style={{ width: "100%", border: "none", pointerEvents: "none" }} />
      ) : (
        <div className="mx-2 flex-row-column-start">{valueText}</div>
      )}
    </div>
  );
};

interface SectionHeaderProps {
  headerText: string;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const { headerText } = props;
  return (
    <Row className={`flex-row-start`}>
      <Col className={`flex-row-start`}>
        <h5 style={{ marginBottom: 0 }}>{headerText}</h5>
      </Col>
    </Row>
  );
};

export const JobCompletionSignatureFields = () => {
  return (
    <section className="full-width" style={{ pageBreakInside: "avoid" }}>
      <div className="flex-row-start">
        <h6>{"WIN GOLD ENGINEERING & SURVEYING LTD"}</h6>
      </div>
      <div className="flex-row-start align-items-start" style={{ marginTop: "80px" }}>
        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Company Representative"}
          <br />
          {"WIN GOLD 代表"}
        </div>
        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Client's Representative"}
          <br />
          {"客戶代表"}
        </div>

        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Contact Telephone"}
          <br />
          {"客戶聯絡電話"}
        </div>

        <div className="flex1" style={{ borderTop: "solid 1px #000", marginRight: "16px" }}>
          {"Date"}
          <br />
          {"日期"}
        </div>
      </div>
    </section>
  );
};
interface StarCardProps {
  title: string;
  score: number;
}

export const StarCardForPrint = (props: StarCardProps) => {
  const { title, score } = props;

  return (
    <section className="my-2 flex-center full-width" style={{ height: "48px" }}>
      <div className="full-width flex-center" style={{ height: "48px" }}>
        <div
          style={{
            fontSize: "1.2em",
            marginLeft: "0.5em",
            marginRight: "1.5em",
            whiteSpace: "nowrap",
          }}
          className="flex-row-end"
        >
          {title}
        </div>
        <div className="flex-row-start" style={{ position: "relative", height: "100%" }}>
          <div className="px-1 flex-row-around starContainer">
            {Array(5)
              .fill(0)
              .map((item, idx) => {
                return (
                  <div
                    key={idx + 10}
                    className="p-1 flex-column-center mx-1"
                    style={{ minWidth: "64px", maxWidth: "64px" }}
                  >
                    <StarIcon position={`${title}_${idx}`} offset={Math.max(0, Math.min(1, score - idx))} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
