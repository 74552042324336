import { infoBarStatus, infoBarStatusUpdate } from "App";
import { push } from "connected-react-router";
import style from "css/InfoBar.module.scss";
import {
  A_CREATE_NEW_TEMPLATE,
  A_DASHBOARD,
  A_VIEW_CLIENT,
  A_VIEW_COMPANY,
  A_VIEW_DOC_TYPE,
  A_VIEW_WORKER,
} from "models/authorities";
import { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { resetWorkPagination } from "redux/Order/action";
import { setInfoBarActive } from "redux/Routing/action";
import { IRootState } from "store";

export default function InfoBar() {
  const dispatch = useDispatch();
  const barOpen = useContext(infoBarStatus);
  const setBarOpen = useContext(infoBarStatusUpdate) as unknown as React.Dispatch<React.SetStateAction<boolean>>;
  const [serviceIsOpen, setServiceIsOpen] = useState(true);
  const [adminIsOpen, setAdminIsOpen] = useState(true);

  const handleServiceClick = () => setServiceIsOpen(!serviceIsOpen);
  const handleAdminClick = () => setAdminIsOpen(!adminIsOpen);
  const currentActive = useSelector((state: IRootState) => state.infoBar.infoBar.isActive);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const currentLocation = window.location.pathname;

  const commonTabs = [
    { to: "/all-orders", title: "所有工作單" },
    { to: "/service", title: "工程工作單" },
    { to: "/maintenance", title: "維修保養工作單" },
    { to: "/order-history", title: "工作單記錄" },
  ];

  const adminTabs = [
    A_VIEW_DOC_TYPE.includes(userRole) && { to: "/admin/document", title: "文件類型" },
    A_CREATE_NEW_TEMPLATE.includes(userRole) && { to: "/admin/template", title: "文件範本" },
    A_VIEW_COMPANY.includes(userRole) && { to: "/admin/company", title: "合作公司資料" },
    A_VIEW_WORKER.includes(userRole) && { to: "/admin/worker", title: "員工資料" },
    A_VIEW_CLIENT.includes(userRole) && { to: "/admin/client", title: "客戶資料" },
    A_DASHBOARD.includes(userRole) && { to: "/admin/dashboard", title: "Dashboard" },
  ].filter((i) => !!i);

  const infoBarTabs = {
    order: commonTabs,
    admin: adminTabs,
  };

  const allTabs = infoBarTabs.order.concat(infoBarTabs.admin as any);

  useEffect(() => {
    const currentTab = () => {
      try {
        if (currentLocation === "/service") {
          dispatch(setInfoBarActive(allTabs.indexOf(allTabs.filter((i) => i.to === "/service")[0])));
        } else if (currentLocation === "/maintenance") {
          dispatch(setInfoBarActive(allTabs.indexOf(allTabs.filter((i) => i.to === "/maintenance")[0])));
        } else {
          for (let i = allTabs.length - 1; i < allTabs.length; i--) {
            if (currentLocation.includes(allTabs[i].to)) {
              dispatch(setInfoBarActive(i));
              break;
            }
          }
        }
      } catch (error) {
        dispatch(setInfoBarActive(currentActive));
        dispatch(push(allTabs![currentActive].to));
      }
    };
    currentTab();
  }, [dispatch]);

  return (
    <div id={barOpen ? style["openedInfoBar"] : style["closedInfoBar"]}>
      <div
        className="pointer"
        id={style["indicator"]}
        style={{
          zIndex: 10,
          transform: barOpen ? "rotate(180deg)" : "rotate(0deg)",
          backgroundColor: barOpen ? "#6099f8" : "#FFF",
          border: barOpen ? "solid 0px #FFF" : "solid 1px #888",
        }}
        onClick={() => {
          setBarOpen(!barOpen);
        }}
      >
        <MdKeyboardArrowRight style={{ fill: barOpen ? "white" : "#444" }} />
      </div>
      <div id={style["mainContent"]}>
        <h3 className="my-2">管理後台</h3>
        <div>
          <div onClick={handleServiceClick}>
            工作單
            <span>
              <MdKeyboardArrowDown
                className="pointer"
                size={24}
                style={{
                  transform: serviceIsOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                }}
              />
            </span>
          </div>
          <Collapse isOpen={serviceIsOpen}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {infoBarTabs.order.map((item, idx) => {
                return (
                  <InfoBarTabs
                    key={idx + `${item.title}`}
                    to={item.to}
                    title={item.title}
                    isActive={currentActive === idx}
                    idx={idx}
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
        {infoBarTabs.admin.length && (
          <div>
            <div onClick={handleAdminClick}>
              行政
              <span>
                <MdKeyboardArrowDown
                  size={24}
                  className="pointer"
                  style={{
                    transform: adminIsOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.4s",
                  }}
                />
              </span>
            </div>
            <Collapse isOpen={adminIsOpen}>
              <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                {infoBarTabs.admin &&
                  infoBarTabs.admin.map((item: any, idx) => {
                    return (
                      <InfoBarTabs
                        key={item + idx.toString()}
                        to={item.to}
                        title={item.title}
                        isActive={currentActive === idx + infoBarTabs.order.length}
                        idx={idx + infoBarTabs.order.length}
                      />
                    );
                  })}
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  );
}

interface InfoBarTabsProps {
  title: string;
  to: string;
  idx: number;
  isActive: boolean;
}
const InfoBarTabs = (props: InfoBarTabsProps) => {
  const { title, to, isActive, idx } = props;
  const dispatch = useDispatch();
  return (
    <NavLink
      onClick={() => {
        dispatch(setInfoBarActive(idx));
        dispatch(resetWorkPagination());
      }}
      to={to}
      className={isActive ? style["chosen"] : style["subInfo"]}
    >
      {title}
    </NavLink>
  );
};
